<template>
  <ftx-dialog
    :dialog="dialog"
    :position="isMobile ? 'bottom' : 'standard'"
    :maximized="isMobile ? true : false"
    :persistent="true"
    className="dialog-md"
    :before-show="onShow"
    :onHide="onHide"
  >
    <template v-slot:DialogContent>
      <q-card
        class="simpleDialog q-pa-none"
        :class="isMobile ? 'mobileCardDialogue' : ''"
      >
        <div class="dialogHeader">
          <q-btn
            class="close absolute-top-right"
            padding="md"
            flat
            v-close-popup
          >
            <SvgIcon icon="close" size="14px" />
          </q-btn>
          <div class="row">
            <div
              class="col-12 text-center"
              :class="isMobile ? 'q-px-md' : 'q-px-xl'"
            >
              <h4 class="text-h6 text-weight-bold text-black">
                Update Frequency & Schedule
              </h4>
              <div class="text-subtitle1 line-height-15 text-black">
                You can update the
                <strong>'Frequency'</strong> and
                <strong>'Rescheduled Date'</strong> for your orders. Any changes
                you make will immediately update in your
                <strong>'Upcoming Orders'</strong> section and will apply to
                your next scheduled order.
              </div>
            </div>
          </div>
        </div>

        <q-card-section
          class="text-center q-pa-none q-pt-lg"
          :class="isMobile ? 'q-px-md' : 'q-px-xl'"
        >
          <q-form @submit="onSubmit">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="form-field">
                  <q-select
                    outlined
                    v-model="formObj.frequency"
                    :options="frequencyOptions"
                    option-value="interval"
                    option-label="label"
                    emit-value
                    map-options
                    label="Your Current Frequency"
                    :rules="[(val) => validateRequired(val)]"
                    :lazy-rules="lazyValidationRules"
                  />
                </div>
              </div>
              <div class="col-12 col-md-12">
                <div class="form-field">
                  <q-input
                    outlined
                    dense
                    :value="selectedNextOrderDate"
                    label="Your next order on"
                    placeholder="YYYY-MM-DD"
                    :rules="[(val) => validateRequired(val)]"
                    :lazy-rules="lazyValidationRules"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          cover
                          ref="qDateProxy"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="calendarDate"
                            mask="YYYY-MM-DD"
                            :options="minTodayDate"
                          >
                            <div
                              class="row items-center justify-end q-gutter-sm"
                            >
                              <q-btn
                                label="Cancel"
                                color="primary"
                                flat
                                v-close-popup
                                @click="cancelDateSelection"
                              />
                              <q-btn
                                label="OK"
                                color="primary"
                                flat
                                v-close-popup
                                @click="selectDate"
                              />
                            </div>
                          </q-date>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </div>
              <div class="col-12 col-md-12">
                <div class="form-action">
                  <div
                    class="row q-col-gutter-md"
                    :class="isMobile ? '' : 'q-pb-lg'"
                  >
                    <div class="col">
                      <q-btn
                        unelevated
                        no-caps
                        outline
                        class="q-btn-lg full-width"
                        v-close-popup
                      >
                        Cancel
                      </q-btn>
                    </div>
                    <div class="col">
                      <q-btn
                        unelevated
                        no-caps
                        color="primary"
                        class="q-btn-lg full-width"
                        :loading="loading"
                        type="submit"
                      >
                        Update
                      </q-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
function initialState() {
  return {
    dialog: 'ChangeFrequencyDialog',
    formObj: {
      frequency: null,
      nextOrderDate: null,
    },
    calendarDate: null,
    loading: false,
  }
}

export default {
  name: 'ChangeFrequencyDialog',
  data: function () {
    return initialState()
  },
  computed: {
    selectedNextOrderDate() {
      let selectedDate = ''
      if (this.formObj.nextOrderDate) {
        selectedDate = this.$options.filters.formatDate(
          this.formObj.nextOrderDate,
          'MMM DD, YYYY'
        )
      }
      return selectedDate
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    frequencyOptions() {
      let frequencies = this.properties?.frequencies || []
      const getSubFrequency = (frequency) => {
        return `Every ${frequency} ${frequency > 1 ? 'Days' : 'Day'}`
      }
      return frequencies?.map((frequency) => {
        return {
          id: frequency.id,
          interval: frequency.intervalDays,
          label: getSubFrequency(frequency.intervalDays),
        }
      })
    },
  },
  methods: {
    onShow() {
      if (this.properties.frequency && this.properties.frequencies?.length) {
        let freqExists = this.properties.frequencies.find(
          (item) => item.intervalDays === this.properties.frequency
        )
        if (freqExists) this.formObj.frequency = this.properties.frequency
      }
      if (this.properties.nextOrderDate) {
        this.calendarDate = this.$options.filters.formatDate(
          this.properties.nextOrderDate,
          'YYYY-MM-DD'
        )
        this.selectDate()
      } else {
        let tDate = new Date()
        tDate.setDate(tDate.getDate() + 1)
        this.calendarDate = this.$options.filters.formatDate(
          tDate,
          'YYYY-MM-DD'
        )
        this.selectDate()
      }
    },
    onHide() {
      Object.assign(this.$data, initialState())
    },
    minTodayDate(d) {
      let tDate = new Date()
      tDate.setDate(tDate.getDate() + 1)
      return tDate.toISOString().slice(0, 10).split('-').join('/') <= d
    },
    selectDate() {
      this.formObj.nextOrderDate = this.calendarDate
    },
    cancelDateSelection() {
      this.calendarDate = this.formObj.nextOrderDate
    },
    onSubmit() {
      let reqObj = {
        id: this.properties.subscriptionID,
        params: {
          actionType: this.properties.actionType,
          frequency: this.formObj.frequency,
          nextOrderDate: this.$options.filters.formatDate(
            this.formObj.nextOrderDate,
            'YYYY-MM-DD'
          ),
        },
      }
      this.loading = true
      this.$store
        .dispatch('order/updateSubscriptionAction', reqObj)
        .then(async (response) => {
          if (
            this.properties?.refreshListing &&
            response?.data?.subscriptionId
          ) {
            this.loading = false
            this.properties.refreshListing()
            this.changeDialogState({
              dialog: this.dialog,
              val: false,
            })
            this.showSuccess('Your subscription is updated.')
          } else this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
  },
}
</script>
